import React from "react";
import "./AboutSection.css";
import Button from "../../../../components/button/Button";

const AboutSection = () => {

    const handleDownload = (url) => {
        window.open(url, '_blank');
    };

    const handleEmailClick = () => {
        window.location.href = 'mailto:yelyzavetakhmyz@gmail.com'; 
    };

    return (
        <div className="main-wrapper">
            <div className="about-section-overlap">
                <div className="about-section-container">
                    <div className="image-section">
                        <img src="/assets/pages/about-page/about-avatar.svg" alt="Avatar"/>
                    </div>
                    <div className="info-container">
                        <div className="text-container">
                            <h1>
                                About me
                            </h1>
                            <p>
                                I am a recent Computer Science graduate from the best technical university of Ukraine, 
                                currently based in Myrtle Beach, SC. 
                                <br /><br />
                                My background has equipped me with a strong blend of technical expertise, 
                                from analyzing and estimating roofing projects
                                to developing software apps and implementing machine learning algorithms.
                                <br /><br />
                                I am passionate about creating software solutions that delivers impactful digital experiences and highly motivated to continuously develop new skills. 
                                <br /><br />
                                <span>
                                    Now I am looking for opportunities to gain practical experience that will allow me to refine my skills and make meaningful contributions to the community where I am now.
                                </span>
                            </p>
                        </div>
                        <div className="button-container">
                            <Button onClick={() => handleDownload('/diploma.png')} text="Diploma" className="primary-button" ></Button>
                            <Button onClick={handleEmailClick} text="Contact" className="primary-button" ></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutSection;