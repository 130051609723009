const dim_calendar_sql = `
-- Cleansed DIM_Date Table --
SELECT 
  [DateKey], 
  [FullDateAlternateKey] AS Date, 
  --[DayNumberOfWeek], 
  [EnglishDayNameOfWeek] AS Day, 
  --[SpanishDayNameOfWeek], 
  --[FrenchDayNameOfWeek], 
  --[DayNumberOfMonth], 
  --[DayNumberOfYear], 
  --[WeekNumberOfYear],
  [EnglishMonthName] AS Month, 
  Left([EnglishMonthName], 3) AS MonthShort,   
    -- Useful for front end date navigation and front end graphs.
  --[SpanishMonthName], 
  --[FrenchMonthName], 
  [MonthNumberOfYear] AS MonthNo, 
  [CalendarQuarter] AS Quarter, 
  [CalendarYear] AS Year --[CalendarSemester], 
  --[FiscalQuarter], 
  --[FiscalYear], 
  --[FiscalSemester] 
FROM 
 [AdventureWorksDW2019].[dbo].[DimDate]
WHERE 
  CalendarYear >= YEAR(GETDATE()) -2 
    -- Ensures only bring two years of date are brought from extraction.
`;

const dim_customers_sql = `
-- Cleansed DIM_Customers Table --
SELECT 
  c.customerkey AS CustomerKey, 
  --      ,[GeographyKey]
  --      ,[CustomerAlternateKey]
  --      ,[Title]
  c.firstname AS [First Name], 
  --      ,[MiddleName]
  c.lastname AS [Last Name], 
  c.firstname + ' ' + lastname AS [Full Name], 
  -- Combined First and Last Name
  --      ,[NameStyle]
  --      ,[BirthDate]
  --      ,[MaritalStatus]
  --      ,[Suffix]
  CASE c.gender WHEN 'M' THEN 'Male' WHEN 'F' THEN 'Female' END AS Gender,
  --      ,[EmailAddress]
  --      ,[YearlyIncome]
  --      ,[TotalChildren]
  --      ,[NumberChildrenAtHome]
  --      ,[EnglishEducation]
  --      ,[SpanishEducation]
  --      ,[FrenchEducation]
  --      ,[EnglishOccupation]
  --      ,[SpanishOccupation]
  --      ,[FrenchOccupation]
  --      ,[HouseOwnerFlag]
  --      ,[NumberCarsOwned]
  --      ,[AddressLine1]
  --      ,[AddressLine2]
  --      ,[Phone]
  c.datefirstpurchase AS DateFirstPurchase, 
  --      ,[CommuteDistance]
  g.city AS [Customer City] -- Joined in Customer City from Geography Table
FROM 
  [AdventureWorksDW2019].[dbo].[DimCustomer] as c
  LEFT JOIN dbo.dimgeography AS g ON g.geographykey = c.geographykey 
ORDER BY 
  CustomerKey ASC -- Ordered List by CustomerKey
`

const dim_products_sql = `
-- Cleansed DIM_Products Table --
SELECT 
  p.[ProductKey], 
  p.[ProductAlternateKey] AS ProductItemCode, 
  --      ,[ProductSubcategoryKey], 
  --      ,[WeightUnitMeasureCode]
  --      ,[SizeUnitMeasureCode] 
  p.[EnglishProductName] AS [Product Name], 
  ps.EnglishProductSubcategoryName AS [Sub Category], 
    -- Joined in from Sub Category Table
  pc.EnglishProductCategoryName AS [Product Category], 
    -- Joined in from Category Table
  --      ,[SpanishProductName]
  --      ,[FrenchProductName]
  --      ,[StandardCost]
  --      ,[FinishedGoodsFlag] 
  p.[Color] AS [Product Color], 
  --      ,[SafetyStockLevel]
  --      ,[ReorderPoint]
  --      ,[ListPrice] 
  p.[Size] AS [Product Size], 
  --      ,[SizeRange]
  --      ,[Weight]
  --      ,[DaysToManufacture]
  p.[ProductLine] AS [Product Line], 
  --     ,[DealerPrice]
  --      ,[Class]
  --      ,[Style] 
  p.[ModelName] AS [Product Model Name], 
  --      ,[LargePhoto]
  p.[EnglishDescription] AS [Product Description], 
  --      ,[FrenchDescription]
  --      ,[ChineseDescription]
  --      ,[ArabicDescription]
  --      ,[HebrewDescription]
  --      ,[ThaiDescription]
  --      ,[GermanDescription]
  --      ,[JapaneseDescription]
  --      ,[TurkishDescription]
  --      ,[StartDate], 
  --      ,[EndDate], 
  ISNULL (p.Status, 'Outdated') AS [Product Status] 
FROM 
  [AdventureWorksDW2019].[dbo].[DimProduct] as p
  LEFT JOIN dbo.DimProductSubcategory AS ps ON 
    ps.ProductSubcategoryKey = p.ProductSubcategoryKey 
  LEFT JOIN dbo.DimProductCategory AS pc ON 
    ps.ProductCategoryKey = pc.ProductCategoryKey 
order by 
  p.ProductKey asc
`

const fact_internalSales_sql = `
-- Cleansed FACT_InternetSales Table --
SELECT 
  [ProductKey], 
  [OrderDateKey], 
  [DueDateKey], 
  [ShipDateKey], 
  [CustomerKey], 
  --  ,[PromotionKey]
  --  ,[CurrencyKey]
  --  ,[SalesTerritoryKey]
  [SalesOrderNumber], 
  --  [SalesOrderLineNumber], 
  --  ,[RevisionNumber]
  --  ,[OrderQuantity], 
  --  ,[UnitPrice], 
  --  ,[ExtendedAmount]
  --  ,[UnitPriceDiscountPct]
  --  ,[DiscountAmount] 
  --  ,[ProductStandardCost]
  --  ,[TotalProductCost] 
  [SalesAmount] --  ,[TaxAmt]
  --  ,[Freight]
  --  ,[CarrierTrackingNumber] 
  --  ,[CustomerPONumber] 
  --  ,[OrderDate] 
  --  ,[DueDate] 
  --  ,[ShipDate] 
FROM 
  [AdventureWorksDW2019].[dbo].[FactInternetSales]
WHERE 
  LEFT (OrderDateKey, 4) >= YEAR(GETDATE()) -2 
    -- Ensures only bring two years of date are brought from extraction.
ORDER BY
  OrderDateKey ASC
`

export const pr2_section1 = [
    <h2>Overview</h2>,
    <p>
        <br/>
        This project aimed to create an executive sales report tailored for sales managers.
        <br/><br/>
        User stories were defined to guide the development process.
        <br/><br/>
        Azure Data Studio and SQL queries were used to cleanse and transform data 
        from the AdventureWorks database. 
        <br/><br/>
        Power BI was utilized to visualize and analyse data.
        <br/><br/>
        The final dashboard provides a comprehensive overview of 
        internet sales trends, allowing users to track performance 
        and make informed decisions based on customer and product insights.
    </p>,
    <h3>Keywords</h3>,
    <p className='keywords'>
        <span className='keyword'>SQL</span>
        <span className='keyword'>SQL queries</span>
        <span className='keyword'>Microsoft Power BI</span>
        <span className='keyword'>Azure Data Studio</span>
        <span className='keyword'>SQL Server</span>
        <span className='keyword'>Database management system</span>
        <span className='keyword'>Data analysis</span>
        <span className='keyword'>Data visualization</span>
        <span className='keyword'>Business Intelligence</span>
        <span className='keyword'>BI solutions</span>
        <span className='keyword'>Data Model</span>
    </p>
];

export const pr2_section2 = [
    <h2>Business request & User stories</h2>,
    <p>
        <br/>
        To meet the business requirements, a set of user stories was defined to guide the project's delivery 
        and ensure that the acceptance criteria were consistently maintained throughout the process.
        <br/><br/>
    </p>,
    <table>
    <thead>
      <tr>
        <th>№</th>
        <th>As a (role)</th>
        <th>I want (request / demand)</th>
        <th>So that I (user value)</th>
        <th>Acceptance criteria</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>Sales manager</td>
        <td>To get a dashboard overview of internet sales</td>
        <td>Can follow better which customers and products sell the best</td>
        <td>A Power BI dashboard which updates data once a day</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Sales representative</td>
        <td>A detailed overview of Internet Sales per Customers</td>
        <td>Can follow up my customers that buy the most and who we can sell more to</td>
        <td>A Power BI dashboard which allows me to filter data for each customer</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Sales representative</td>
        <td>A detailed overview of Internet Sales per Products</td>
        <td>Can follow up my Products that sell the most</td>
        <td>A Power BI dashboard which allows me to filter data for each Product</td>
      </tr>
      <tr>
        <td>4</td>
        <td>Sales manager</td>
        <td>A dashboard overview of internet sales</td>
        <td>Follow sales over time against budget</td>
        <td>A Power BI dashboard with graphs and KPIs comparing against budget.</td>
      </tr>
    </tbody>
  </table>
];

export const pr2_section3 = [
    <h2>Data preperation</h2>,
    <p>
        <br/><br/>
        To create the required data model for analysis and to meet the business requirements outlined in the user stories, 
        the following tables were extracted and prepared using SQL in Azure Data Studio.
    </p>,
    <p>
        The sales budget data source was provided in Excel format and later integrated into the data model during the subsequent stages of the process. 
        Below are the SQL statements used for cleansing and transforming the required data.
        <br/><br/>
    </p>,
    <h4>DIM_Calendar</h4>,
    <pre>
      <div className='script'>
        {dim_calendar_sql}
      </div>
    </pre>,
    <h4>DIM_Customers</h4>,
    <pre>
      <div className='script'>
        {dim_customers_sql}
      </div>
    </pre>,
    <h4>DIM_Product</h4>,
    <pre>
      <div className='script'>
        {dim_products_sql}
      </div>
    </pre>,
    <h4>FACT_InternalSales</h4>,
    <pre>
      <div className='script'>
        {fact_internalSales_sql}
      </div>
    </pre>,

];

export const pr2_section4 = [
    <h2 key="1">Data model</h2>,
    <p key="2">
        <br/>
        Below is a data model after cleansed and prepared tables were read into Power BI.
    </p>,
    <img src={"/assets/projects/content/sales-man/d-model.png"} alt="UML diagram" loading="lazy"></img>,
];

export const pr2_section5 = [
    <h2 key="1">Sales management dashboard</h2>,
    <p key="2">
        <br/>
        The completed sales management dashboard consists of a main overview page that serves as a comprehensive dashboard, 
        along with two additional pages dedicated to consolidating tables for essential details. 
        <br/><br/>
        These pages provide visualizations that illustrate sales trends over time, 
        as well as breakdowns by customer and product.
        <br/><br/>
    </p>,
    <iframe title="sales overview" width="100%" height="400px" src="https://app.powerbi.com/view?r=eyJrIjoiNzUyZDEyNjgtZmI1Zi00ZjdlLWEzM2QtNmE5YTE1NTNhZjU5IiwidCI6IjI0NTZlYTg4LWU3YzMtNDZhNC1hZGY3LTc3OTlmOWZjZDhmZCIsImMiOjZ9" frameborder="0" allowFullScreen="true"></iframe>
];

export const pr2_section6 = [
    <h2>Conclusions</h2>,
    <p>
        <br/><br/>
        The implemented project successfully achieved its objective 
        of providing sales managers and representatives with an 
        intuitive and actionable tool to analyze sales trends. 
        <br/><br/>
        By leveraging SQL for data preparation and cleansing, 
        and Power BI for visualization, the project delivered a 
        robust business intelligence solution that meets the 
        defined user stories and business requirements.
    </p>
];