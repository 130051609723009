import React from "react";
import ProjectPageTemplate from "../../components/project-page-template/ProjectPageTemplate";
import { projectsData } from "../../data/ProjectsData";

const SalesManPage = () => {

    const project = projectsData.find(p => p.key === "sales-management");

    return (
        <ProjectPageTemplate 
            avatar={project.avatarSRC}
            header={project.header}
            description={project.description} 
            sections={project.sections}
        /> 
    );

};

export default SalesManPage;