import { gptOverviewSection, 
    gptSolutionSection,
    gptOptionSection, 
    gptDatasetCreationSection,
    gptPromptCreationSection,
    gptResultsGenSection,
    gptFineTunningSeciton,
    gptEvaluationSection,
    gptEfResultsSection,
    gptConclusionSection} from "../pages/projects-page/projects-page-content/GPTTrialMatchPageContent";
import { iiAPISection, iiConclustionsSection, iiDevelopmentToolsSection, iiOverviewSection, iiParallelismSection, iiPerformanceComparisonSeciton, iiResultsSection, iiServerModuleSeciton, iiSystemComponentsSection, iiSystemModelSection, iiTestingSection, iiUMLDiagramSection } from "../pages/projects-page/projects-page-content/InvIndexPageContent";
import { pr2_section1, pr2_section2, pr2_section3, pr2_section4, pr2_section5, pr2_section6 } from "../pages/projects-page/projects-page-content/SalesManPageContent";
import { stConclusionsSection, stDesignSection, stDevelopmentSection, stOverviewSection, stPlanningSection } from "../pages/projects-page/projects-page-content/SleepTrackerPageContent";

export const projectsData = [
    {
        key: "gpt-trial-match",
        header: "GPT-TrialMatch",
        description: "Innovative solution to the patients matching to clinical trials problem.",
        avatarSRC: "/assets/projects/llm.svg",
        sections: [
            { id: "Overview", content: gptOverviewSection },
            { id: "Proposed solution", content: gptSolutionSection },
            { id: "LLM building strategy", content: gptOptionSection },
            { id: "Dataset generation", content: gptDatasetCreationSection },
            { id: "Prompt creation", content: gptPromptCreationSection },
            { id: "Relults generation", content: gptResultsGenSection },
            { id: "LLM fine tunning", content: gptFineTunningSeciton },
            { id: "LLM evaluation implementation", content: gptEvaluationSection },
            { id: "Results", content: gptEfResultsSection },
            { id: "Conclusions", content: gptConclusionSection },
        ]
    },
    {
        key: "inverted-index",
        header: "Inverted Index",
        description: "System for building and using the “Inverted Index” data structure.",
        avatarSRC: "/assets/projects/inv-index.svg",
        sections: [
            { id: "Overview", content: iiOverviewSection },
            { id: "Development tools", content: iiDevelopmentToolsSection },
            { id: "System components", content: iiSystemComponentsSection },
            { id: "UML diagram", content: iiUMLDiagramSection },
            { id: "Client-Server system model", content: iiSystemModelSection },
            { id: "Inverted index module", content: iiParallelismSection },
            { id: "Server module", content: iiServerModuleSeciton },
            { id: "Performancecomparison module", content: iiPerformanceComparisonSeciton },
            { id: "API testing", content: iiAPISection },
            { id: "System testing", content: iiTestingSection },
            { id: "Results", content: iiResultsSection },
            { id: "Conclusions", content: iiConclustionsSection }
        ]
    },
    {
        key: "sleep-tracker",
        header: "SleepTracker",
        description: "System for monitoring, analyzing and improving sleep quality.",
        avatarSRC: "/assets/projects/alarm.svg",
        sections: [
            { id: "Overview", content: stOverviewSection },
            { id: "Planning and requirement analysis", content: stPlanningSection },
            { id: "System design", content: stDesignSection },
            { id: "Development", content: stDevelopmentSection },
            { id: "Conclusions", content: stConclusionsSection },
        ]
    },
    {
        key: "sales-management",
        header: "Sales Management",
        description: "Comprehensive sales report to meet business requirements.",
        avatarSRC: "/assets/projects/data-analysis.svg",
        sections: [
            { id: "Overview", content: pr2_section1 },
            { id: "Business requests", content: pr2_section2 },
            { id: "Data preperation", content: pr2_section3 },
            { id: "Data model", content: pr2_section4 },
            { id: "Sales management dashboard", content: pr2_section5 },
            { id: "Conclusions", content: pr2_section6 }
        ]
    },
];

